<template>
  <h3 class="main-title">
      <slot></slot>
  </h3>
</template>

<script>
export default {
    name: 'main-title'
}
</script>

<style scoped>
.main-title{
    font-size: 2.2em;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #EE7F1D;
    padding: 5px 20px;
    margin: 5px 0;
    border: 2px solid #EE7F1D;
    border-left-width: 20px;
    display: inline-block;
}
@media (max-width: 1199px){
   .main-title{
       font-size: 1.8em;
   } 
}    
@media (max-width: 880px){
   .main-title{
       font-size: 26px;
   } 
} 
@media (max-width: 414px){
   .main-title{
       font-size: 16px;
   } 
} 
</style>