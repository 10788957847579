<template>
  <div class="container">
    <main-title>Извините, страница не найдена, ошибка 404</main-title>
  </div>
</template>

<script>
import MainTitle from "@/components/UI/MainTitle.vue";

export default {
  components: {
    MainTitle
  }
  
}
</script>

<style scoped>

</style>