<template>
  <div class="shadow-block ">
    <div class="container">
      <div class="meshki">
        <main-title>ПОЛИПРОПИЛЕНОВЫЕ МЕШКИ</main-title>
        <main-text class="tab"> Наиболее востребованный вид современной тары для сыпучей продукции — полипропиленовые
        мешки: прочные, удобные, недорогие, стойкие к влаге и низким температурам, спокойно
        выдерживающие значительный вес. Они идеально подходят для хранения и транспортировки муки,
        сахара, фасоли, круп, зерна, удобрений и строительных смесей. Это отличная и незаменимая упаковка,
        применяемая во многих сферах бизнеса. Материал изготовления – полипропилен, безопасен для
        здоровья и практичен.</main-text>
        <main-text class="tab"> Стоимость полипропиленовых мешков рассчитывается менеджерами компании и зависит прежде
        всего от тех требований, которые вы предъявляете к их изготовлению. Полипропиленовые мешки в
        зависимости от назначения по видам продукции могут иметь разный вес, размер, различную плотность,
        иметь или не иметь логотип, ламинацию, полиэтиленовый вкладыш, клапан. И таким образом, исходя из
        всех выше перечисленных характеристик, и складывается общая стоимость/цена полипропиленовых
        мешков.</main-text>    
      </div>
    </div>  
  </div> 
  <div class="container">
    <div class="meshki-img">
      <h3 class="meshki-title">На сегодняшний день мы можем предложить:</h3>
      <div class="items">
        <div class="item">
          <img src="../../public/img/meshki/polip.jpg" width="200" height="222" alt="мешки полипропиленовые без лого">
          <p class="meshki-txt">МЕШКИ ПОЛИПРОПИЛЕНОВЫЕ БЕЗ ЛОГО</p>
        </div>
        <div class="item">
          <img src="../../public/img/meshki/fleksop.jpg" width="328" height="220" alt="мешки с флексопечатью">
          <p class="meshki-txt">МЕШКИ <br>С ФЛЕКСОПЕЧАТЬЮ</p>
        </div>
        <div class="item">
          <img src="../../public/img/meshki/polnotsv.jpg" width="220" height="220" alt="мешки с полноцветной печатью">
          <p class="meshki-txt">МЕШКИ<br> С ПОЛНОЦВЕТНОЙ ПЕЧАТЬЮ</p>
        </div>     
        <div class="item with-kg">
          <img src="../../public/img/meshki/ruchnoi.jpg" width="220" height="220" alt="мешки с ручкой на 5-10кг">
          <span class="meshki-txt kg">5-10 кг</span>
          <p class="meshki-txt">МЕШКИ С РУЧКОЙ НА 5 – 10 КГ</p>
        </div>  
        <div class="item">
          <img src="../../public/img/meshki/klapanom.jpg" width="220" height="220" alt="мешки с клапаном, с полиэтиленовым вкладышем">
          <p class="meshki-txt">МЕШКИ С КЛАПАНОМ,<br> С ПОЛИЭТИЛЕНОВЫМ ВКЛАДЫШЕМ</p>
        </div>         
        <div class="item">
          <img src="../../public/img/meshki/prozrach.jpg" width="220" height="220" alt="мешки прозрачные, цветные">
          <p class="meshki-txt">МЕШКИ ПРОЗРАЧНЫЕ, ЦВЕТНЫЕ</p>
        </div>  
        <div class="item">
          <img src="../../public/img/meshki/big.jpg" width="220" height="220" alt="мешки биг-беги">
          <p class="meshki-txt">БИГ-БЕГИ</p>
        </div>                                          
      </div>
    </div>    
  </div> 
</template>
<script>
import MainTitle from "@/components/UI/MainTitle.vue";
import MainText from "@/components/UI/MainText.vue";

export default {
  components: {
    MainTitle,
    MainText,
  }  
}
</script>
<style scoped>
.shadow-block{
  margin: 5px 0;
  padding: 20px 0;
  -webkit-box-shadow: 0px 10px 15px 5px rgb(206, 206, 206); 
  box-shadow: 0px 10px 15px 5px rgb(206, 206, 206);
}
.meshki{
  padding: 0 100px
}
.item.with-kg{
  position: relative;
}
.meshki-txt.kg{
  position: absolute;
  right: 13px;
  bottom: 150px;
}
.meshki-img{
  margin-top: 50px;
}
.tab {
  margin: 0;
  text-indent: 3ch;
  text-align: justify;
}
.meshki-title{
    background: #ee7f1d; /* Old browsers */
    background: -moz-linear-gradient(left,  #ee7f1d 0%, #f3ae36 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ee7f1d 0%,#f3ae36 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,#ee7f1d 0%,#f3ae36 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#f3ae36',GradientType=1 ); /* IE6-9 */

    clip-path: polygon(3% 20%, 100% 20%, 100% 78%, 97% 100%, 0 100%, 0 40%);
    text-align: center;
    line-height: 1.50rem;
    padding: 25px 20px 13px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 26px;
    color: #fff; 
    width: 60%;
    text-align: center;
    margin: 0 auto 5px;
}
.items{
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.item{
  margin: 20px 0px;
  width: 25%;
  text-align: center;
}
.meshki-txt{
  text-align: center;
  font-size: 18px;
  width: 293px;
  padding: 0px 20px 10px;
  font-weight: 700;
  color: #636060;
}

@media (max-width: 1399px){
  .meshki{
    padding: 50px;
  }
  .meshki-title{
    font-size: 22px;    
  }  
  .meshki-img{
    margin-top: 15px;
  }
  .meshki-txt.kg{
    right: -10px;
}
} 
@media (max-width: 1199px){
  .item{
    width: 33%;
  }
  .meshki{
    padding: 0;
  }
    .meshki-title{
    font-size: 20px;
    padding: 20px 10px 10px;
  }
  .meshki-txt.kg{
  right: 10px;
}
}
@media (max-width: 991px){
  .meshki-txt{
    font-size: 14px;
  }
  .meshki-title{
    width: 90%;
  }
  .item{
    width: 50%;
  }
  .meshki-txt.kg{
  right: 30px;
  bottom: 115px;
  }
} 
@media (max-width: 767px){
  .container, .container-sm {
    max-width: 660px !important;
    padding-left: 35px;
  }   
  .meshki-title{
    width: 100%;
    margin: 0;
  } 
  .items, .item{
    margin: 5px 0;
  }
} 
@media (max-width: 623px){
  .meshki-title{
    padding: 20px 5px 10px;
  }
}
@media (max-width: 540px){
  .meshki-txt.kg{
  right: -28px;
}
}
@media (max-width: 414px){
  .meshki-title{
    font-size: 12px;
    padding: 15px 5px 5px;
  }
  .item{
    width: 100%;
  }
  .meshki-txt{
    width: 100%;
  }
  .meshki-txt.kg{
    right: 10px;
    bottom: 100px;
}
}
</style>

