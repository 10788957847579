<template>
  <div class="container">      
      <div class="items">
        <main-title class="mar-b">УСЛУГИ</main-title>
        <h5 class="subtitle">ВАШ ПЕРВЫЙ ВИЗИТ К НАМ В КОМПАНИЮ</h5>
        <main-text class="service-txt">Вы приходите к нам с идеей или задачей, которую необходимо решить с учётом ваших условий в
            заданные сроки.</main-text>
      </div>   
      <div class="items">
        <h5 class="subtitle">КОНКРЕТИЗАЦИЯ ПОСТАВЛЕННОЙ ЗАДАЧИ</h5>
        <main-text class="service-txt">Мы конкретизируем и реализовываем вашу идею – переводим ее в технологически выполнимые
            операции и изготавливаем образец (в цифровом виде)</main-text>
      </div>
      <div class="items">
        <h5 class="subtitle">УТВЕРЖДЕНИЕ И ВЫПУСК ПЕРВОГО ТИРАЖА</h5>
        <main-text class="service-txt">Как только образец утверждён, мы приступаем к финальной части - выпуск тиража, который вам
            необходим.</main-text>
      </div>  
      <div class="items">
        <div class="service-offer">
            <main-text class="offer-txt">Мы готовы предложить Вам наши услуги, по поставке полипропиленовых мешков
              производства Республики Казахстан, и надеемся на долгосрочное и устойчивое
              сотрудничество.</main-text>
        </div>                   
      </div>
  </div>
</template>

<script>
import MainText from '@/components/UI/MainText.vue'
import MainTitle from '@/components/UI/MainTitle.vue'
export default {
  components: { MainText, MainTitle },

}
</script>

<style scoped>
.items{
    margin: 35px 0;
    padding: 0 100px;
}
.service-offer{
    padding: 10px;
    margin: 20px 0;
    border-left: 40px solid #EE7F1D;
}
.subtitle{
    font-weight: 700;
    font-size: 26px;
    margin: 0;
}
.mar-b{
  margin-bottom: 35px;
}
.service-txt{
    padding: 0;
    text-align: justify;
}
.offer-txt{
    padding-left: 15px;
    font-size: 24px;
    font-weight:700;
    text-align: justify;
}
@media (max-width: 1399px){
  .offer-txt{
    font-size: 18px;
  }
}  
@media (max-width: 1199px){
  .items{
    padding: 0;
  }
} 
@media (max-width: 960px) {
  .subtitle{
    font-size: 22px;
}  
}
@media (max-width: 767px){
  .container, .container-sm {
    max-width: 660px !important;
    padding-left: 35px;
  }   
}   
@media (max-width: 414px) {
  .subtitle{
    font-size: 14px;
} 
.service-offer{
    border-width: 20px;
}
}
</style>