<template>
    <footer>
        <h5 class="footer-title">Отправьте заявку, указав телефон или почту</h5>                    
            <form ref="form" @submit.prevent="sendEmail">
                <div class="container footer">
                    <div class="form-block">
                        <input type="text" v-model="name" name="name" class="name form-control" placeholder="Ваше имя">
                    </div>
                    <div class="form-block">
                        <input type="email" v-model="email" name="email" class="email form-control" placeholder="Ваша почта" id="exampleInputEmail1" aria-describedby="emailHelp">
                    </div>
                    <div class="form-block">
                        <input type="text" v-model="phone" name="phone" class="phone form-control" placeholder="Ваш телефон">
                    </div>
                </div>    
                <button type="submit" value="Send" class="btn submit">Оставить заявку</button>
            </form>   
    </footer>

</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    methods: {
        validate() {
            let name = document.querySelector(".name")
            let email = document.querySelector(".email")
            let phone = document.querySelector(".phone")
            let submit = document.querySelector(".submit")

            submit.addEventListener("click", (e) =>{
                e.preventDefault()

                if (phone.value == "") {
                    this.inputErrorMessege();
                }
                else{
                    this.sendEmail(name.value, email.value, phone.value);
                    this.getSuccessMessege();
                    console.log('email sent')
                }
            } )
        },
        
        sendEmail(){
            emailjs.sendForm('service_pilvb67', 'template_e5kh4oj', this.$refs.form, 'YMhUW4JgRJYb0rRMX')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                }, //(error) => {
                    //console.log('FAILED...', error.text);
                //}
                ),
                this.validate()
        },
        

        getSuccessMessege(){
            swal({
                title: "Спасибо за заявку!",
                text: "Ваша заявка успешно отправлена!",
                icon: "success",
                button: "ОК!",
            });
        },  
        getErrorMessege(){
            swal({
                title: "Ошибка!",
                text: "Ваша заявка не отправлена, заполните форму!",
                icon: "error",
                button: "ОК!",
            });
        },  
        inputErrorMessege(){
            swal({
                title: "Ошибка!",
                text: "Ваша заявка не отправлена, укажите номер телефона !",
                icon: "error",
                button: "ОК!",
            });
        },   
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer{
    width: 100%;    
    text-align: center;
    background: #cecece;
    padding: 30px 50px;

}
.footer{
    display: flex;
    padding: 0 130px;
}
.footer-title{
    font-size: 22px;
    padding: 5px 20px 20px;
}
.form-block{
    width: 35%;
}
.form-control{
    margin: 5px;
    padding: 10px;
    width: 95%;
    border: 2px solid #5e5b5b5d;
}
.form-control::placeholder{
    font-size: 20px;
}
.btn{
    padding: 7px 90px;
    background: #EE7F1D;
    border-radius: 0px;
    margin: 5px;
    font-size: 20px;
    color: #fff;
}
@media (max-width: 991px){
  .footer{
    padding: 0px;
  }
} 
@media (max-width: 767px){
  .form-block{
    width: 50% !important;
  }
} 
@media (max-width: 540px){
  .footer-title{
    font-size: 16px;
  }
  .footer{
      display: inline-block;
  }
  .form-control{
      width: 100%;
  }
  .form-block{
      width: 100% !important;
  }
  .btn{
      padding: 5px;
      width: 100%;
  }
}
    
</style>
