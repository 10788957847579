<template>
  <div class="container">
    <div class="items">
      <div class="item">
        <main-title>О КОМПАНИИ</main-title>
        <main-text class="justify">ОсОО “Универсал Трейдинг", с 2016 года является официальным дистрибьютором завода
          производителя полипропиленовых мешков в Кыргызской Республики.
          Наша продукция изготавливается на новейшем Австрийском оборудовании с переработкой
          исключительно первичного сырья и использованием высококачественных зарубежных добавок, что
          дает возможность делать прочную и экологически чистую упаковку для использования в различных
          отраслях.</main-text>
      </div>
      <div class="item">
        <ul>
          <main-title class="title-triangle">ПОЧЕМУ ВЫБИРАЮТ НАС</main-title>
          <li><i></i><main-text class="triangle-txt">Изготовление полипропиленовых мешков из высококачественного сырья;</main-text></li>
          <li><i></i><main-text class="triangle-txt">Использование импортного современного оборудования;</main-text></li>
          <li><i></i><main-text class="triangle-txt">Полипропиленовые мешки не имеют отклонений от заданных параметров, не имеют замечаний 
          при прохождении контроля качества;</main-text></li>
          <li><i></i><main-text class="triangle-txt">Купленные у нас полипропиленовые мешки – надежны, не рвутся и сохраняют товарный вид;</main-text></li>  
          <li><i></i><main-text class="triangle-txt">После нанесения логотипа закупленные полипропиленовые мешки долгое время сохраняют
            надпись и рисунок;</main-text></li>
          <li><i></i><main-text class="triangle-txt">Минимальные сроки изготовления;</main-text></li>
          <li><i></i><main-text class="triangle-txt">Возможность изготовления клише за счет завода изготовителя (зависит от объема заказа);</main-text></li>
          <li><i></i><main-text class="triangle-txt">Мы не испытываем проблем с грузоперевозками и транспортными средствами, наши поставки
            стабильны!</main-text></li>
          <li><i></i><main-text class="triangle-txt">Покупка полипропиленовых мешков оптом со значительной скидкой, с возможностью отсрочки по
            оплате.</main-text></li>
        </ul>    
      </div>
      <div class="item">
        <main-title>НАШИ КЛИЕНТЫ</main-title>
        <main-text>«Акун», «Золотой поток», «Яшар», «ОсОО Ай Зи», «ОсОО Бин Групп», «АО Карасу Дан Азык», «Орион»,
«Москва», «Береке» и др.</main-text>
        <div class="clients">
          <div class="client">
            <img src="../../public/img/clients/yashar.jpg" width="210" height="220" alt="мешки для муки">
          </div>
          <div class="client">
            <img src="../../public/img/clients/yashar-pervyi.jpg" width="210" height="220" alt="мешки для муки">
          </div> 
          <div class="client">
            <img src="../../public/img/clients/moskva.jpg" width="210" height="220" alt="мешки для муки">
          </div>  
          <div class="client">
            <img src="../../public/img/clients/dastorkon.jpg" width="210" height="220" alt="мешки для муки">
          </div>  
          <div class="client">
            <img src="../../public/img/clients/akun-pervyi.jpg" width="210" height="220" alt="мешки для муки">
          </div> 
          <div class="client">
            <img src="../../public/img/clients/kazak-nan.jpg" width="210" height="220" alt="мешки для муки">
          </div>  
          <div class="client">
            <img src="../../public/img/clients/aliya.jpg" width="210" height="220" alt="мешки для муки">
          </div>  
          <div class="client">
            <img src="../../public/img/clients/akun-elima.jpg" width="210" height="220" alt="мешки для муки">
           </div>   
          <div class="client">
            <img src="../../public/img/clients/ahsan.jpg" width="210" height="220" alt="мешки для муки">
          </div>            
          <div class="client">
            <img src="../../public/img/clients/bereke-pervyi.jpg" width="210" height="220" alt="мешки для муки">
          </div> 
          <div class="client">
            <img src="../../public/img/clients/orion-pervyi.jpg" width="210" height="220" alt="мешки для муки">
          </div>  
          <div class="client">
            <img src="../../public/img/clients/narodnyi.jpg" width="210" height="220" alt="мешки для муки">
          </div>  
          <div class="client">
            <img src="../../public/img/clients/abada.jpg" width="210" height="220" alt="мешки для муки">
          </div>
          <div class="client">
            <img src="../../public/img/clients/oskar.jpg" width="210" height="220" alt="мешки для муки">
          </div> 
          <div class="client">
            <img src="../../public/img/clients/zvezda.jpg" width="210" height="220" alt="мешки для муки">
          </div>                                                                                                                    
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import MainTitle from "@/components/UI/MainTitle.vue";
import MainText from "@/components/UI/MainText.vue";

export default {
  components: {
    MainTitle,
    MainText,
  }
  
}
</script>
<style scoped>
.item{
  padding-bottom: 50px;
  margin-bottom: 15px;
}
.item .justify{
  text-align: justify;
}
.title-triangle{
  margin-bottom: 25px;
}
.triangle-txt{
  margin: 0;
  padding: 0 10px 3px 0px;
}
ul {
  margin: 0.75em 0;
  padding: 0 1em;
  list-style: none;
}
li{
  display: flex;
}
li i::before { 
  content: "";
  margin-top: 7px;
  margin-right: 15px;
  border-color: transparent #ee7e1d;
  border-style: solid;
  border-width: 15px 0 15px 30px;
  display: inline-block;
  height: 0;
  width: 0;
}
.clients{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 10px;
}
.client{
  position: relative;
  width: 212px;  
  border: 1px solid #cecece;
  margin: 3px;
}
.items{
  padding: 0 100px;
}

@media (max-width: 1399px){
  .items{
    padding: 50px;
  }
  .clients{
    margin-left: 0px;
  }

}
@media (max-width: 1299px){
  .items{
    padding: 0px;
  }
}
@media (max-width: 1199px){
  .items{
    padding: 0px;
  }
}
@media (max-width: 767px){
  li i::before { 
  margin-right: 10px;
  border-width: 10px 0 10px 18px;
}
 .item{
    padding-bottom: 30px;
    margin-bottom: 0;
  }
  .client{
    width: 45%;
    text-align: center;
  }
  .container, .container-sm {
      max-width: 660px !important;
      padding-left: 35px;
  }
  .item .justify{
  text-align: center;
  }
}
@media (max-width: 534px){
  .client{
    width: auto;
  }
}
@media (max-width: 414px){
  .item{
    padding-bottom: 0;
  }
  .client{
    margin: 3px auto;
  }
}  
</style>
