<template>
  <div class="main-block">
    <img class="main-pic" src="../../public/img/pics/main-pic.jpg" width="2000" height="757" alt="универсал трейдинг">
    <div class="main-flex container"> 
      <div class="slogan-box"> 
        <h1 class="slogan">ПРОДАЖА ПОЛИПРОПИЛЕНОВЫХ МЕШКОВ</h1>
      </div>  
      <div class="meshok-box">   
        <img src="../../public/img/pics/meshok.png" width="606" height="677" alt="универсал трейдинг продажа полипропиленовых мешков">
      </div>
    </div>  
  </div>
</template>

<script>
export default {
  }
</script>
<style scoped>
.main-block{
  width: 100%;
  height: auto;
  margin-bottom: 35px;
}
.main-flex{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.main-pic{
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    border-top-width: 5px;
    border-top-style: solid;
    border-top-color: #cecece;/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,ffffff+1,cecece+100 */
    -webkit-box-shadow: 0px 10px 10px 5px rgb(206, 206, 206); 
    box-shadow: 0px 10px 10px 5px rgb(206, 206, 206);
}
.meshok-box{
  width: 42%;
  position: absolute;
  top: 12.5em;
  right: 0px;
}

@media (max-width: 1420px){
  .meshok-box{
    margin-left: 40px; 
  }
  .meshok-box img{
    width: 556px;
    height: 627px;
  }
}
.slogan-box{
  position: absolute;
  /*top: 12.5em;
  left: 10.31em;*/
  top: 17.5em;
  left: 18.31em;
  width: 40%;
  filter:  drop-shadow(20px 20px 7px rgba(182, 178, 178, 0.8));
}
.slogan{
    background: #ee7f1d; /* Old browsers */
    background: -moz-linear-gradient(left,  #ee7f1d 0%, #f3ae36 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ee7f1d 0%,#f3ae36 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,#ee7f1d 0%,#f3ae36 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#f3ae36',GradientType=1 ); /* IE6-9 */

    clip-path: polygon(12% 20%, 100% 20%, 100% 78%, 88% 100%, 0 100%, 0 40%);
    text-align: center;
    line-height: 3.50rem;
    padding: 90px 20px 35px;
    width: 100%;

    font-weight: 700;
    text-transform: uppercase;
    font-size: 44px;
    color: #fff; 
}

.input-group{
    width: 500px;
}
.form-control{
    border: 1px solid #ee7f1d;
    position: relative;
}
.btn-secondary{
    background: #ee7f1d;
    border: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 100%;    
}
@media (max-width: 1458px){
  .meshok-box{
    width: 45%;
  }
  .slogan-box{
    top: 12.5em;
    left: 10.31em;
  }  
}  
@media (max-width: 1420px){
  .slogan{
    font-size: 2.25em;
  }
}

@media (max-width: 1280px){
  .slogan-box{
    left: 6em;
  }
  .meshok-box{
    top: 10.0em;
  }
  .meshok-box img{
    width: 506px;
    height: 577px;    
  }
  .meshok-box{
    width: 50%;
  }
}
@media (max-width: 1199px){
  .slogan-box{
    top: 10em;
    width: 45%;
    }
  .meshok-box{
    margin: 0;
  }
  .meshok-box img{
    width: 476px;
    height: 537px;
  }
  .meshok-box{
    width: 45%;
  }
}  
@media (max-width: 1120px){
  .meshok-box img{
    width: 456px;
    height: 507px;
  }
  .slogan{
    font-size: 2.10em;
    line-height: 1.50em;
    padding: 80px 20px 27px;
  }
}  
@media (max-width: 991px){
  .slogan{
    font-size: 1.8em;
  }
  .meshok-box img{
    width: 406px;
    height: 447px;
  }
}  
@media (max-width: 960px){
  .meshok-box img{
    width: 366px;
    height: 407px;
  }
  .slogan-box{
    width: 40%;
    }
  .slogan{
    font-size: 24px;
  }
}  
 
@media (max-width: 860px){
  .meshok-box img{
    width: 346px;
    height: 387px;
  }
}  
@media (max-width: 810px){
  .meshok-box img{
    width: 336px;
    height: 377px;
  }
  .meshok-box{
    top: 8.8em;
  }
  .slogan-box{
    width: 47%;
    left: 3.0em;
  }
  .slogan{
    padding: 60px 15px 20px;
  }
}  
@media (max-width: 810px){
  .meshok-box{
    width: 50%;
  }
    .meshok-box img{
    width: 316px;
    height: 357px;
  }
}  
@media (max-width: 695px){
  .meshok-box{
    width: 45%;
  }
    .meshok-box img{
    width: 296px;
    height: 327px;
  }
  .slogan-box{
    width: 50%;
  }
} 
@media (max-width: 640px){
  .slogan-box{
    width: 40%;
    left: 1.5em;
  }
  .meshok-box img{
  width: 286px;
  height: 307px;
  }
  .meshok-box{
    width: 47%;
  }
  .slogan{
    font-size: 18px;
    padding: 45px 15px 15px;
  }
} 
@media (max-width: 600px){
  .slogan-box{
    width: 45%;
  }
  .meshok-box{
    width: 55%;
  }
  .meshok-box img{
    width: 266px;
    height: 287px;
  }
} 
@media (max-width: 600px){
  .meshok-box img{
    width: 236px;
    height: 257px;
  }
  .meshok-box{
    width: 50%;
  }
}  
@media (max-width: 414px){
  .meshok-box img{
    width: 206px;
    height: 227px;
  }
  .meshok-box{
    width: 50%;
    top: 8.0em;
  }
  .slogan-box{
    top: 8.0em;
    width: 50%;
  }
  .slogan{
    font-size: 14px;
  }  
}  
@media (max-width: 393px){
  .meshok-box img{
    width: 177px;
    height: 197px;
  }
  .main-block{
    margin-bottom: 0;
  }
}
@media (max-width: 393px){
  .slogan-box{
    width: 52%;    
  }
  .slogan{
    padding-right: 10px;
  }  
}  
@media (max-width: 280px){
.slogan-box{
    top: 5em;
    width: 90%;}
  .slogan{
    font-size: 12px;
    padding: 20px 5px 10px;
  }  
  .meshok-box img{
    width: 77px;
    height: 97px;
  }
    
}    
</style>
