<template>
  <div class="container">
    <main-title>КОНТАКТЫ</main-title>
    <p class="contact-subtitle">Общество с ограниченной ответственностью «Универсал Трейдинг»</p>
    <div class="contacts">
      <p class="item-stitle phone"><span>Тел.: </span>+996 (700) 20 55 22</p>
      <p class="item-stitle wapp"><span>WhatsApp: </span>+996 (555) 20 55 22</p>
      <p class="item-stitle email"><span>E-mail: </span>office_universal@mail.ru</p>
      <p class="item-stitle map">Кыргызская Республика, г. Бишкек, ул. Калыка Акиева, 66</p>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/UI/MainTitle.vue";

export default {
  components: {
    MainTitle
  }
  
}
</script>

<style scoped>
.contacts{
  font-size: 24px;
  padding-right: 20px;
}
.contact-subtitle{
    font-size: 24px;
    font-weight: 700;
    padding: 20px 100px 20px 0;    
}
.item-stitle span{
    font-weight: 700;
}
.item-stitle::before{
  content: '';
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
}
.phone{
  background: url('../assets/phone50px.png') 0 -2px no-repeat;
}
.wapp{
  background: url('../assets/whapp.png') 0 -2px no-repeat;
}
.email{
  background: url('../assets/e-mail.png') 0 -2px no-repeat;
}
.map{
  background: url('../assets/maps.png') 0 -2px no-repeat;
}
.container{
  max-width: 1000px;
}
@media (max-width: 1260px){
  .container{
    padding-left: 100px;
  }
}  
@media (max-width: 414px){
  .container{
    padding-left: 30px;
  }
  .contacts,
  .contact-subtitle{
    font-size: 14px;
} 
} 
</style>