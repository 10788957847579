<template>
<nav class="navbar navbar-expand-lg navbar-light container">
  <div class="container-fluid">
    <a class="navbar-brand" @click="$router.push('/')"><img src="../assets/logo.png" width="125" height="135" alt="Универсал трейдинг"></a>
    <div class="burger">
      <div class="hamburger" @click="hamburgerOpen = !hamburgerOpen" :class="hamburgerOpen ? 'hamburger--is-open' : ''">
        <div class="hamburger__item hamburger__item--first"></div>
        <div class="hamburger__item hamburger__item--middle"></div>
        <div class="hamburger__item hamburger__item--last"></div>
      </div>
    </div>  
    <transition name="slide-fade">
    <div  class="collapse navbar-collapse show" id="navbarSupportedContent" v-show="!hamburgerOpen" @click="hideMenu= !hideMenu">
      <ul name="menu" class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link active-class="active-link" to="/">О нас</router-link>
        </li>
        <li class="nav-item">
          <router-link active-class="active-link" exact-active-class="exact-active-link" to="/meshki">Мешки</router-link>
        </li>
        <li class="nav-item">
          <router-link active-class="active-link" exact-active-class="exact-active-link" to="/service">Услуги</router-link>
        </li>
        <li class="nav-item">              
          <router-link active-class="active-link" exact-active-class="exact-active-link" to="/contacts">Контакты</router-link>
      </li>        
      </ul>  
    </div>
    </transition>
    <div class="contacts">
        <i class="ic-phone"></i>
        <p class="phone-txt">+996 (700) 20 55 22<br> +996 (555) 20 55 22</p>
    </div>
  </div>
</nav>  
</template>

<script>
export default {
  data() {
    return {
      hamburgerOpen: true,
      // collapsed: true,
      show: false
    };
  },
  methods: {
    hideMenu(){      
      setTimeout(() => this.hamburgerOpen = !this.hamburgerOpen, 1000)      
    }
  }
}  
</script>

<style scoped>

.bg-blue-bright {
  background-color: #EE7F1D;
}
.burger{
  display: none;
  position: absolute;
  top: 20px;
  right: 12px;
}
.hamburger {
  border: 1px solid #EE7F1D;
  border-radius: 3px;
  background: #fff;
  height: 45px;
  width: 62px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
} 
.hamburger:hover {
    cursor: pointer;
  }
  
.hamburger__item {
    height: 3px;
    width: 100%;
    background: #EE7F1D;
    transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
    opacity 300ms linear;
}    
/* .hamburger--is-open .hamburger__item.hamburger__item--first{
        transform: translate(0, 20px) rotate(40deg);
}    
.hamburger--is-open .hamburger__item--middle{
        opacity: 0;
}    
.hamburger--is-open .hamburger__item.hamburger__item--last{
        transform: translate(0, -20px) rotate(-40deg);
} */
a{
  text-decoration: none;
}
.navbar{
  font-size: 24px;
  font-weight: 700;
}
.navbar-brand{
  padding: 0 50px 0 0;
  cursor: pointer;
}
.navbar-toggler {
    color: #EE7F1D;
    border-color: #EE7F1D;
}  
.nav-item{
    margin: 0 10px;
}
.nav-item a:not(.active, .exact-active-link){   
    padding: 10px 25px;
    color: #000 !important;
    border: 1px solid #fff;
}

.navbar-nav .nav-item .active,
.navbar-nav .nav-item .exact-active-link{
    padding: 10px 25px;
    color: #EE7F1D !important;
    border: 1px solid rgb(243, 174, 54) !important;
} 
.navbar-nav .nav-item a:hover{
  padding: 10px 25px;
  color: #EE7F1D !important;
  border: 1px solid #fff;
}
.contacts{
    display: flex;
    position: absolute;
    right: 0;
    text-align: right;
}
.ic-phone{
    width: 68px;
    height: 70px;
    display: block;
    background: url('../assets/phone70.png');
}
.phone-txt{
    width: 220px;
    font-weight: normal;
}
@media (max-width: 1399px){
  .phone-txt{
    font-size: 22px;
    width: 200px;
  }
  .nav-item{
    margin: 0;
  }
  .nav-item a:not(.active, .exact-active-link){
    padding: 10px 20px !important;
  }
}
@media (max-width: 1199px){
  .navbar-brand{
    margin: 0;
  }
  .navbar-brand img{
    width: 105px;
    height: 115px;
  }
  .navbar{
    font-size: 20px;
  }
    .phone-txt{
    font-size: 18px;
    width: 165px;
    padding-top: 10px;
  }
  .nav-item a:not(.active, .exact-active-link){
    padding: 10px 20px !important;
  }
}  
@media (max-width: 991px){

  .navbar-brand{
    padding-right: 5px;
  }
  .navbar-brand img{
    width: 95px;
    height: 105px;
  }
  .contacts{
    right: 0.0em;
    bottom: 0;
    padding-right: 10px;
    margin: 0;
  }
  .phone-txt{
    padding: 0;
    margin: 0;
  }
  .ic-phone{
    width: 50px;
    height: 50px;
    display: block;
    background: url('../assets/phone50px.png');
}
  .phone-txt{
    font-size: 16px;
    font-weight: bold;
    width: 80%;
    padding: 0px;
  }
  .burger{
    display: block;
  }
  .slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
  .nav-item{
    width: 100%;
    border-bottom: 1px solid #fff;
    padding: 5px 0;
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.collapse.show{
    font-weight: 400;
    text-transform: uppercase;
    width: 95%;
    z-index: 100;
    background: rgba(243, 174, 54, 0.9);
    position: absolute;
    top: 65px;
    right: 12px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border: 1px solid #EE7F1D;
    padding: 20px;
  }
  .nav-item a:not(.active, .exact-active-link),
  .navbar-nav .nav-item .active,
  .navbar-nav .nav-item .exact-active-link  {
    border: none !important;
    padding: 10px 20px 0 !important;
    width: 100%;
    display: block;
  }
  .navbar-nav{
    margin-bottom: 0 !important;
  }
}  
  
@media (max-width: 767px){
  .container, .container-sm {
      max-width: 660px !important;
  }
}
@media (max-width: 280px){
  .navbar-brand img{
    width: 55px;
    height: 65px;
  }
}
</style>