import { createRouter, createWebHistory } from 'vue-router'
import AboutUs from '@/pages/AboutUs.vue';
import MeshkiView from '@/pages/MeshkiView.vue';
import ServiceView from '@/pages/ServiceView.vue';
import ContactsView from '@/pages/ContactsView.vue';
import PageNotFound from '@/pages/PageNotFound.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: AboutUs
  },
  {
    path: '/meshki',
    name: 'meshki',
    component: MeshkiView
  },
  {
    path: '/service',
    name: 'service',  
    component: ServiceView
  },
  {
    path: '/contacts',
    name: 'contacts',  
    component: ContactsView
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
