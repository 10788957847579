<template>
  <p class="main-txt">
      <slot></slot>
  </p>
</template>

<script>
export default {
    name: 'main-text'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;600&family=Roboto+Condensed:ital,wght@0,300;0,700;1,300&family=Roboto:wght@400;500&display=swap');
.main-txt{
    font-family: 'Roboto', sans-serif;
    font-size: 1.8em;
    font-weight: 400;
    padding: 10px 20px 10px 0;
    margin: 5px 0;
    line-height: 1.5em;
}
@media (max-width: 1199px){
  .main-txt{
    font-size: 1.5em;
  }
}
@media (max-width: 540px){
  .main-txt{
    font-size: 18PX;
  }
}
@media (max-width: 414px){
  .main-txt{
    font-size: 14PX;
  }
}
</style>