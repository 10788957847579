<template>
  <nav-bar></nav-bar>
  <main-block></main-block>
  <div class="app">
    <router-view></router-view>
  </div>
  <footer-form></footer-form>
</template>

<script>
import NavBar from '@/components/NavBar';
import MainBlock from '@/components/MainBlock';
import FooterForm from '@/components/FooterForm';

export default {
  components: {
    NavBar,
    MainBlock,
    FooterForm
  }
}
</script>

<style>


</style>
